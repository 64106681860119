import React, { useState } from 'react';
import AmmarImage from '../assets/Ammar.png';


const Testimonials = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(null);

  const testimonials = [
   
    {
      id: 2,
      quote: "Working with this company has been a game-changer. Their AI tools helped us streamline our workflow and reduce operational costs. Amazing results!",
      name: "Ammar Ahmad",
      title: "CEO of Its It Group",
      imgUrl: AmmarImage
    }
    
  ];

  const handleClick = (id) => {
    setActiveTestimonial(id === activeTestimonial ? null : id);
  };

  return (
    <section className="relative isolate bg-gradient-to-br from-white to-gray-50 px-8 py-24 sm:py-32 lg:px-16">
      <div className="mx-auto max-w-7xl grid gap-12 grid-cols-1 md:grid-cols-3">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`relative group transition-all duration-700 ease-in-out ${
              activeTestimonial === testimonial.id
                ? 'scale-105 shadow-neumorphic-active'
                : 'scale-100 shadow-neumorphic'
            } bg-gradient-to-br from-white to-gray-50 rounded-2xl p-10 hover:scale-105 hover:shadow-neumorphic-active cursor-pointer transform`}
            onClick={() => handleClick(testimonial.id)}
            style={{
              backdropFilter: 'blur(15px)',
              border: activeTestimonial === testimonial.id ? '1px solid rgba(255, 255, 255, 0.6)' : 'none',
            }}
          >
            <figure className="relative">
              <blockquote
                className={`text-center text-lg md:text-xl font-semibold tracking-wider text-gray-900 ${
                  activeTestimonial === testimonial.id
                    ? 'leading-relaxed text-gray-800'
                    : 'leading-tight line-clamp-3'
                } transition-all duration-500`}
              >
                <p className="relative">
                  <span className="absolute -left-3 top-0 text-blue-500 text-5xl opacity-20">“</span>
                  {testimonial.quote}
                  <span className="absolute -right-3 bottom-0 text-blue-500 text-5xl opacity-20">”</span>
                </p>
              </blockquote>
              <figcaption className="mt-6 flex justify-center items-center space-x-4 transition-opacity duration-500">
                <img
                  className="h-16 w-16 rounded-full object-cover ring-4 ring-blue-100 shadow-xl transition-transform group-hover:scale-110"
                  src={testimonial.imgUrl}
                  alt={testimonial.name}
                />
                <div className="text-left">
                  <div className="font-bold text-gray-900 group-hover:text-blue-600 transition-colors duration-300">{testimonial.name}</div>
                  <div className="text-gray-500 text-sm">{testimonial.title}</div>
                </div>
              </figcaption>
            </figure>
            {activeTestimonial === testimonial.id && (
              <div className="mt-6 text-center text-gray-600 text-sm transition-opacity duration-500">
                <p className="italic">&quot;Click again to collapse&quot;</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
