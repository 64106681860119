import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { FiChevronDown, FiMenu, FiX } from 'react-icons/fi';
import {
  FaMobileAlt, FaLaptopCode, FaBrain, FaPalette, FaCity,
  FaHeartbeat, FaMoneyCheckAlt, FaShoppingCart, FaGavel
} from 'react-icons/fa';
import logo from '../assets/logos.png';

const Header = () => {
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const [isIndustriesMenuOpen, setIsIndustriesMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrollOpacity, setScrollOpacity] = useState(0.9);
  
  let servicesTimeout;
  let industriesTimeout;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxOpacityScroll = 300;
      const opacity = Math.min(scrollTop / maxOpacityScroll + 0.9, 1);
      setScrollOpacity(opacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Services menu handlers with delay
  const handleServicesMouseEnter = () => {
    clearTimeout(servicesTimeout);
    setIsServicesMenuOpen(true);
  };

  const handleServicesMouseLeave = () => {
    servicesTimeout = setTimeout(() => {
      setIsServicesMenuOpen(false);
    }, 200); // 200ms delay before closing
  };

  // Industries menu handlers with delay
  const handleIndustriesMouseEnter = () => {
    clearTimeout(industriesTimeout);
    setIsIndustriesMenuOpen(true);
  };

  const handleIndustriesMouseLeave = () => {
    industriesTimeout = setTimeout(() => {
      setIsIndustriesMenuOpen(false);
    }, 200); // 200ms delay before closing
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-opacity duration-500 ease-in-out bg-white shadow-lg ${scrollOpacity < 1 ? 'shadow-md' : ''}`}
      style={{ opacity: scrollOpacity }}
    >
      <nav className="mx-auto flex max-w-6xl items-center justify-between p-4 lg:px-6" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-12 w-auto" src={logo} alt="Company Logo" />
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden flex items-center">
        <a
            href="https://calendly.com/ejazafeef/30min"
            target="_blank"
            rel="noopener noreferrer"
          
          className="text-sm font-semibold leading-6 text-indigo-600 mr-4">
            Hire a team <span aria-hidden="true">&rarr;</span>
          
          </a>
          
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-gray-900 focus:outline-none"
          >
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex lg:gap-x-8">
          {/* Services Dropdown */}
          <div
            className="relative group"
            onMouseEnter={handleServicesMouseEnter}
            onMouseLeave={handleServicesMouseLeave}
          >
            <button className="text-sm font-semibold leading-6 text-gray-900 flex items-center hover:text-indigo-600">
              Services <FiChevronDown className="ml-1 transition-transform group-hover:rotate-180" />
            </button>
            {isServicesMenuOpen && (
              <div
                className="absolute top-full mt-2 w-64 lg:w-80 rounded-lg bg-white shadow-lg z-50 transition-all duration-300 ease-in-out"
                onMouseEnter={handleServicesMouseEnter}
                onMouseLeave={handleServicesMouseLeave}
              >
                <ul className="py-4 text-sm text-gray-700 grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <li>
                    <Link to="/mobile-app-development" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                      <FaMobileAlt className="mr-2 text-indigo-600" /> Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/web-development" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                      <FaLaptopCode className="mr-2 text-indigo-600" /> Enterprise Web Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/ai-analytics" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                      <FaBrain className="mr-2 text-indigo-600" /> AI & Data Analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/ux-design" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                      <FaPalette className="mr-2 text-indigo-600" /> UX Design Studio
                    </Link>
                  </li>
                  <li>
                    <Link to="/iot-smart-cities" className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors">
                      <FaCity className="mr-2 text-indigo-600" /> IoT & Smart Cities
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* Industries Dropdown */}
          <div
            className="relative group"
            onMouseEnter={handleIndustriesMouseEnter}
            onMouseLeave={handleIndustriesMouseLeave}
          >
            <button className="text-sm font-semibold leading-6 text-gray-900 flex items-center hover:text-indigo-600">
              Industries <FiChevronDown className="ml-1 transition-transform group-hover:rotate-180" />
            </button>
            {isIndustriesMenuOpen && (
              <div
                className="absolute top-full mt-2 w-64 rounded-lg bg-white shadow-lg z-50 transition-all duration-300 ease-in-out"
                onMouseEnter={handleIndustriesMouseEnter}
                onMouseLeave={handleIndustriesMouseLeave}
              >
                <ul className="py-4 text-sm text-gray-700">
                <ul className="py-4 text-sm text-gray-700">
  <li>
    <ScrollLink
      to="industries"
      smooth={true}
      duration={500}
      className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors cursor-pointer"
    >
      <FaMoneyCheckAlt className="mr-2 text-indigo-600" /> FinTech & InsurTech
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="industries"
      smooth={true}
      duration={500}
      className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors cursor-pointer"
    >
      <FaHeartbeat className="mr-2 text-indigo-600" /> Healthcare
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="industries"
      smooth={true}
      duration={500}
      className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors cursor-pointer"
    >
      <FaShoppingCart className="mr-2 text-indigo-600" /> Retail & Ecommerce
    </ScrollLink>
  </li>
  <li>
    <ScrollLink
      to="industries"
      smooth={true}
      duration={500}
      className="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors cursor-pointer"
    >
      <FaGavel className="mr-2 text-indigo-600" /> E-government
    </ScrollLink>
  </li>
</ul>
                </ul>
              </div>
            )}
          </div>

          <Link to="/about-us" className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 transition-colors">
            About
          </Link>
          <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600 transition-colors">
            Contact
          </Link>
        </div>

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
        
        <a
            href="https://calendly.com/ejazafeef/30min"
            target="_blank"
            rel="noopener noreferrer"
          
          className="text-sm font-semibold leading-6 text-indigo-600 mr-4">
            Hire a team <span aria-hidden="true">&rarr;</span>
          
          </a>
        </div>
      </nav>

      {/* Mobile Menu - Visible on small screens */}
      {isMobileMenuOpen && (
        <div className="lg:hidden absolute top-full left-0 w-full bg-white shadow-lg z-50 transition-all duration-300 ease-in-out">
          <ul className="py-4 text-center">
            <li>
              <Link
                to="/web-development"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block py-2 text-gray-700 hover:bg-gray-100 transition-colors"
              >
                Enterprise Web Development
              </Link>
            </li>
            <li>
              <Link
                to="/mobile-app-development"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block py-2 text-gray-700 hover:bg-gray-100 transition-colors"
              >
                Mobile App Development
              </Link>
            </li>
            
            <li>
              <Link
                to="/ai-analytics"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block py-2 text-gray-700 hover:bg-gray-100 transition-colors"
              >
                AI & Data Analytics
              </Link>
            </li>
            <li>
              <Link
                to="/ux-design"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block py-2 text-gray-700 hover:bg-gray-100 transition-colors"
              >
                UX Design Studio
              </Link>
            </li>
            <li>
              <Link
                to="/iot-smart-cities"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block py-2 text-gray-700 hover:bg-gray-100 transition-colors"
              >
                IoT & Smart Cities
              </Link>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
